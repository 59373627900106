import styled from '@emotion/styled';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'next/router';
import { ChangeEvent, DOMAttributes, useState } from 'react';
import GhostButton from '../../common-components/component-library/buttons/GhostButton';
import PrimaryButton from '../../common-components/component-library/buttons/PrimaryButton';
import TextInput, {
  runValidations,
  validations,
} from '../../common-components/component-library/forms/TextInput';
import Typography, {
  TypographyVariants,
} from '../../common-components/component-library/text/Typography';
import { createTemplateReplacer } from '../../common-components/contentful-elements/util/mergeTagsTemplates';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics/analytics';
import { useMagicLinkCopy } from '../../utils/hooks/useMagicLinkCopy';
import useOnMountEffect from '../../utils/hooks/useOnMountEffect';
import { colors, spacing } from '../../utils/styleguide';
import AlternativeLoginProviders, { EmailAuthenticationMethod } from './AlternativeLoginProviders';
import useResetPasswordUrl from './useResetPasswordUrl';
import firebaseApp from '../../utils/firebase';
import { getSignUpWithEmailLink } from '../../utils/api/authentication';
import { useAuth } from '../../utils/authContext';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]}px;
  margin-bottom: ${spacing[3]}px;
`;

const TextBox = styled(TextInput)`
  margin-bottom: ${spacing[3]}px;
  :last-child {
    margin-bottom: 0;
  }
`;

const ResetPasswordLink = styled(GhostButton)`
  text-align: end;
  margin-bottom: ${spacing[2]}px;
`;

const Submit = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
  width: 100%;
`;

const SignUp = styled.div`
  text-align: center;
`;

const LoadingBox = styled.div`
  text-align: center;
  display: grid;
  row-gap: ${spacing[3]}px;
`;

type PasswordLoginProps = {
  email: string;
  showAllErrors?: boolean;
  error?: unknown;
  onChange: (value: string) => void;
};

const PasswordLogin = ({ email, showAllErrors, error, onChange }: PasswordLoginProps) => {
  const resetPasswordUrl = useResetPasswordUrl(email);

  return (
    <>
      <TextBox
        label="Password"
        id="password"
        name="password"
        type="password"
        skipBlurValidation
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        validations={validations.password}
        validateWithoutFocus={showAllErrors}
        error={getPasswordLoginError(error)}
      />
      <ResetPasswordLink
        href={resetPasswordUrl}
        label="Forgot your password?"
        hideUnderline
        color={colors.info}
        textVariant="detailBold"
      />
    </>
  );
};

type Props = {
  email: string;
  setEmail: (email: string) => void;
};

const StepLogin = ({ email, setEmail }: Props) => {
  const magicLinkCopy = useMagicLinkCopy();

  const { isLoadingUser } = useAuth();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [password, setPassword] = useState('');
  const [sentEmailLink, setSentEmailLink] = useState(false);
  const [loginError, setLoginError] = useState<unknown>();
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [emailAuthenticationMethod, setEmailAuthenticationMethod] =
    useState<EmailAuthenticationMethod>('login-link');

  useOnMountEffect(() => {
    sendEvent({
      name: EVENT_NAMES.viewEnterPassword,
      properties: undefined,
    });
  });

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const logInWithPassword = async ({ email, password }: { email: string; password: string }) => {
    setIsLoggingIn(true);
    setEmail(email);
    setLoginError(undefined);
    try {
      await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);

      setIsLoggingIn(false);
    } catch (error) {
      sendEvent({
        name: EVENT_NAMES.loginLoginFailed,
        properties: {
          method: 'password',
          error,
        },
      });

      setIsLoggingIn(false);
      setLoginError(error);
    }
  };

  const createSignInWithEmailLink = async ({ email }: { email: string }) => {
    setIsLoggingIn(true);
    setEmail(email);
    setLoginError(undefined);
    try {
      await getSignUpWithEmailLink({ email });
      localStorage.setItem('@Auth:storedEmail', email);

      setIsLoggingIn(false);
      setSentEmailLink(true);
    } catch (error) {
      sendEvent({
        name: EVENT_NAMES.loginLoginFailed,
        properties: {
          method: 'email-link',
          error,
        },
      });

      setIsLoggingIn(false);
      setLoginError(error);
    }
  };

  const login: DOMAttributes<HTMLFormElement>['onSubmit'] = async (event) => {
    event.preventDefault();
    const hasEmailValidationError = runValidations(validations.email, email);

    if (hasEmailValidationError) {
      return setShowAllErrors(true);
    }

    if (emailAuthenticationMethod === 'password') {
      const hasPasswordValidationError = runValidations(validations.password, password);
      if (hasPasswordValidationError) {
        return setShowAllErrors(true);
      }
      await logInWithPassword({ email, password });
    } else {
      await createSignInWithEmailLink({ email });
    }
  };

  if (emailAuthenticationMethod === 'login-link' && sentEmailLink) {
    const replacer = createTemplateReplacer({ email });
    return (
      <LoadingBox>
        <Typography variant="h4">A login e-mail is on it&#39;s way</Typography>
        <Typography variant="detail">
          {replacer(magicLinkCopy?.magicLink?.noLinkBody as string)}
        </Typography>
      </LoadingBox>
    );
  }

  return (
    <>
      <Header>
        <Typography variant="h4">Log in to see your donations and stay updated</Typography>
      </Header>
      <form onSubmit={login}>
        <TextBox
          autoFocus
          label="Your email"
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          skipBlurValidation
          validations={validations.email}
          validateWithoutFocus={showAllErrors}
          error={getEmailLoginError(loginError)}
        />
        {emailAuthenticationMethod === 'password' && (
          <PasswordLogin
            email={email}
            showAllErrors={showAllErrors}
            error={loginError}
            onChange={setPassword}
          />
        )}
        <Submit
          type="submit"
          label={emailAuthenticationMethod === 'password' ? 'Log in' : 'Send me a login link!'}
          loading={isLoggingIn || isLoadingUser}
          variant="solid"
        />
      </form>
      <AlternativeLoginProviders
        type="login"
        emailAuthenticationMethod={
          emailAuthenticationMethod === 'login-link' ? 'password' : 'login-link'
        }
        setEmailAuthenticationMethod={(method: EmailAuthenticationMethod) =>
          setEmailAuthenticationMethod(method)
        }
      />
      <SignUp>
        <Typography variant="bodySmall" color={colors.darkGray}>
          New to Milkywire? <CreateAccountButton textVariant="bodySmallBold" />
        </Typography>
      </SignUp>
    </>
  );
};

const CreateAccountButton = ({ textVariant }: { textVariant: TypographyVariants }) => {
  const router = useRouter();
  return (
    <GhostButton
      href={{
        pathname: router.pathname,
        query: {
          ...router.query,
          step: 'sign-up-email',
        },
      }}
      label="Create an account"
      hideUnderline
      color={colors.info}
      textVariant={textVariant}
    />
  );
};

const getEmailLoginError = (error: unknown) => {
  // NOTE: firebase provide email enumeration protection to not leak information on users auth-details
  // hence the error code is generic and does not inform the user if the user exist or not, this
  // is according to security best practices. Hence the helper message is generic.
  if ((error as { code: string } | undefined)?.code === 'auth/invalid-credential') {
    return (
      <>
        Make sure your email is correct. <CreateAccountButton textVariant="detail" /> instead!
      </>
    );
  }
};

const getPasswordLoginError = (error: unknown) => {
  // NOTE: firebase provide email enumeration protection to not leak information on users auth-details
  // hence the error code is generic and does not inform the user if it was the password was wrong, this
  // is according to security best practices. Hence the helper message is generic.
  if ((error as { code: string } | undefined)?.code === 'auth/invalid-credential') {
    return <>Make sure your password is correct.</>;
  }
};

export default StepLogin;
