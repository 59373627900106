import { useEffect, useState } from 'react';
import { getMicroCopy } from '../api/contentful';

export const useMagicLinkCopy = () => {
  const [magicLinkCopy, setMagicLinkCopy] = useState<{
    [key: string]: { [key: string]: string };
  }>();
  useEffect(() => {
    getMicroCopy('magicLink').then((entries) => {
      setMagicLinkCopy(entries);
    });
  }, []);

  return magicLinkCopy;
};
