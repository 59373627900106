import {
  getAnalytics,
  logEvent as logFirebaseEvent,
  setUserProperties as setFirebaseUserProperties,
} from 'firebase/analytics';
import { FirebaseError } from 'firebase/app';
import {
  OAuthCredential,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithCredential,
} from 'firebase/auth';
import Router from 'next/router';
import { SIGNING_METHODS, UserProfile } from '../types/AuthTypes';
import { CurrentUserResponse } from '../types/UserTypes';
import { EVENT_NAMES, sendEvent, setUserProperties } from './analytics';
import { getCurrentUser, getJWTToken } from './api';
import queryClient from './api/queryClient';
import firebaseApp from './firebase';
import isBrowser from './helpers/isBrowser';
import { removeToken, setToken } from './token';
import { captureException, setUser as setSentryUser } from './tracking/sentry/sentry';

function signInProviderToMethod(signInProvider: string | null): string {
  switch (signInProvider) {
    case 'facebook.com':
      return 'facebook';
    case 'google.com':
      return 'google';
    case 'password':
      return 'password';
    case 'emailLink':
      return 'email-link';
    default:
      return signInProvider ?? 'unknown';
  }
}

export async function setUserData(): Promise<CurrentUserResponse>;
export async function setUserData(user: UserProfile): Promise<UserProfile>;
export async function setUserData(user?: UserProfile): Promise<UserProfile | CurrentUserResponse> {
  if (!user) {
    user = await getCurrentUser();
  }

  if (process.env.NODE_ENV !== 'test') {
    setFirebaseUserProperties(getAnalytics(firebaseApp), {
      impacter: user.roles.IMPACTER ? 'true' : 'false',
      supporter: user.categories.SUPPORTER ? 'true' : 'false',
    });
    setUserProperties({
      id: user.id,
      properties: {
        roles: Object.keys(user.roles),
        ...(user.roles.IMPACTER ? { firstName: user.firstName } : {}),
      },
    });
  }
  if (user?.id) {
    setSentryUser({ id: user.id.toString() });
  }

  return user;
}

export async function handleAuthentication() {
  const auth = getAuth(firebaseApp);
  if (!auth.currentUser) {
    return;
  }

  const idTokenResponse = await auth.currentUser?.getIdTokenResult(true);
  if (!idTokenResponse) {
    return;
  }

  const { token, userCreated } = await getJWTToken(idTokenResponse.token);
  if (!token) {
    return;
  }

  setToken(token);
  const method = signInProviderToMethod(idTokenResponse.signInProvider) as SIGNING_METHODS;
  const analytics = getAnalytics(firebaseApp);
  if (userCreated) {
    logFirebaseEvent(analytics, 'sign_up', { method });
    await sendEvent({
      name: EVENT_NAMES.signUpAccountCreated,
      properties: { method },
    });
  }
  logFirebaseEvent(analytics, 'login', { method });
  await sendEvent({ name: EVENT_NAMES.loginLogin, properties: { method } });
  setUserData();
  linkAccounts();
}

export async function logout() {
  await getAuth(firebaseApp).signOut();
  removeToken();
  setSentryUser(null);
  sendEvent({
    name: EVENT_NAMES.logout,
    properties: undefined,
  });
  localStorage.removeItem('asImpacter');
  queryClient.invalidateQueries();
}

export async function linkAccounts() {
  if (!isBrowser()) {
    return;
  }
  const pendingCredentialString = sessionStorage.getItem('@Auth:pendingCredential');
  if (!pendingCredentialString) {
    return;
  }

  const pendingCredential = OAuthCredential.fromJSON(pendingCredentialString);

  if (!pendingCredential) {
    return;
  }

  const firebaseUser = getAuth(firebaseApp).currentUser;
  if (!firebaseUser) {
    return;
  }

  await linkWithCredential(firebaseUser, pendingCredential);
  sessionStorage.removeItem('@Auth:pendingCredential');
}

export const handleSignInRedirectFailed = async ({ error }: { error: unknown }) => {
  sendEvent({
    name: EVENT_NAMES.firebaseSigninRedirectFailed,
    properties: { error },
  });
  if ((error as FirebaseError).code === 'auth/account-exists-with-different-credential') {
    const credentialError = error as FirebaseError;

    // https://github.com/firebase/firebase-js-sdk/issues/5057
    const credential = OAuthProvider.credentialFromError(credentialError);
    const email = credentialError.customData?.email as string | undefined;

    if (!email || !credential) {
      const firebaseMissingDataError = new Error('missing email or credential from firebase error');
      captureException(firebaseMissingDataError);
      throw firebaseMissingDataError;
    }

    const providers = await fetchSignInMethodsForEmail(getAuth(firebaseApp), email);
    // TODO find a better way than sessionStorage
    sessionStorage.setItem('@Auth:pendingCredential', JSON.stringify(credential.toJSON()));
    const returnUrl = Router.query.returnUrl as string;
    Router.push(
      `/link-account?provider=${providers[0]}&pendingProvider=${
        credential.providerId
      }&email=${encodeURIComponent(email)}&returnUrl=${returnUrl}`,
    );
    return;
  }
  captureException(error);
  throw error;
};
