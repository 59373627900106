import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import Router, { useRouter } from 'next/router';
import { ChangeEvent, DOMAttributes, useState } from 'react';
import PrimaryButton from '../../common-components/component-library/buttons/PrimaryButton';
import TextInput, {
  runValidations,
  validations,
} from '../../common-components/component-library/forms/TextInput';
import Typography from '../../common-components/component-library/text/Typography';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics/analytics';
import useOnMountEffect from '../../utils/hooks/useOnMountEffect';
import { colors, spacing } from '../../utils/styleguide';
import AlternativeLoginProviders from './AlternativeLoginProviders';
import firebaseApp from '../../utils/firebase';
import { sendEmailVerification } from '../../utils/api';
import { useAuth } from '../../utils/authContext';
import GhostButton from '../../common-components/component-library/buttons/GhostButton';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]}px;
  margin-bottom: ${spacing[3]}px;
`;

const TextBox = styled(TextInput)`
  margin-bottom: ${spacing[3]}px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Submit = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
`;

type Props = {
  email: string;
};

const StepCreatePassword = ({ email }: Props) => {
  const { isLoadingUser } = useAuth();

  const [isCreatingPassword, setIsCreatingPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [createPasswordError, setCreatePasswordError] = useState<unknown>();
  const [showAllErrors, setShowAllErrors] = useState(false);

  useOnMountEffect(() => {
    sendEvent({
      name: EVENT_NAMES.viewStepCreatePassword,
      properties: undefined,
    });
  });

  const createPassword = async (password: string) => {
    setIsCreatingPassword(true);

    try {
      if (!email) {
        throw new Error('no email');
      }
      const credentials = await createUserWithEmailAndPassword(
        getAuth(firebaseApp),
        email,
        password,
      );

      const { step, ...query }: typeof Router.query = { ...Router.query, email };
      await Router.replace({
        pathname: Router.pathname,
        query,
      });

      const firebaseToken = await credentials.user?.getIdToken(true);
      await sendEmailVerification(firebaseToken, window.location.href);

      setIsCreatingPassword(false);
    } catch (error) {
      sendEvent({
        name: EVENT_NAMES.createPasswordFailed,
        properties: {
          error,
        },
      });

      setCreatePasswordError(error);
      setIsCreatingPassword(false);
    }
  };

  const submit: DOMAttributes<HTMLFormElement>['onSubmit'] = async (e) => {
    e.preventDefault();
    const hasPasswordValidationError = runValidations(validations.password, password);
    if (hasPasswordValidationError) {
      return setShowAllErrors(true);
    }

    await createPassword(password);
  };

  return (
    <>
      <Header>
        <Typography variant="h4">Create account</Typography>
        <Typography variant="h5">See your donations and stay updated!</Typography>
      </Header>
      <form onSubmit={submit}>
        <TextBox
          autoFocus
          label="Create a password"
          id="password"
          type="password"
          value={password}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
          validations={validations.password}
          validateWithoutFocus={showAllErrors}
          error={getCreatePasswordError({ error: createPasswordError, email })}
        />
        <Submit
          type="submit"
          label={'Sign up'}
          disabled={!password}
          loading={isCreatingPassword || isLoadingUser}
          variant="solid"
          css={css`
            max-width: 328px;
            width: 100%;
          `}
        />
      </form>
      <AlternativeLoginProviders type="signup" />
    </>
  );
};

export default StepCreatePassword;

const getCreatePasswordError = ({ error, email }: { error: unknown; email: string }) => {
  if ((error as { code: string } | undefined)?.code === 'auth/email-already-in-use') {
    return (
      <>
        You already have an account. <LoginButton email={email} /> instead! If this was unexpected
        contact us at <a href="mailto:support@milkywire.com">support@milkywire.com</a>.
      </>
    );
  }
};

const LoginButton = ({ email }: { email: string }) => {
  const router = useRouter();
  return (
    <GhostButton
      href={{
        pathname: router.pathname,
        query: {
          ...router.query,
          step: 'login',
          email,
        },
      }}
      label="Log in"
      hideUnderline
      color={colors.info}
      textVariant={'detail'}
    />
  );
};
