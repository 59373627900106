import { useEffect, useState } from 'react';

const useResetPasswordUrl = (email: string) => {
  const [resetPasswordUrl, setResetPasswordUrl] = useState('');
  useEffect(() => {
    setResetPasswordUrl(
      `/reset-password?email=${encodeURIComponent(email)}&from=${encodeURIComponent(
        window.location.href,
      )}`,
    );
  }, [email]);
  return resetPasswordUrl;
};

export default useResetPasswordUrl;
