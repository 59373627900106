import LoginPage from '../domains/login/LoginPage';
import withAppProps from '../utils/withAppProps';
import { AuthProvider } from '../utils/authContext';

const Login = () => (
  <AuthProvider>
    <LoginPage />
  </AuthProvider>
);

export default Login;

export const getServerSideProps = withAppProps(async () => {
  return {
    props: {
      layoutProps: {
        hideNavbarLogo: true,
      },
    },
  };
});
