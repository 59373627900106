import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fetchSignInMethodsForEmail, getAuth } from 'firebase/auth';
import { useRouter } from 'next/router';
import { ChangeEvent, DOMAttributes, useState } from 'react';
import GhostButton from '../../common-components/component-library/buttons/GhostButton';
import PrimaryButton from '../../common-components/component-library/buttons/PrimaryButton';
import ProviderButton from '../../common-components/component-library/buttons/ProviderButton';
import TextInput, {
  runValidations,
  validations,
} from '../../common-components/component-library/forms/TextInput';
import Typography from '../../common-components/component-library/text/Typography';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics/analytics';
import useOnMountEffect from '../../utils/hooks/useOnMountEffect';
import { colors, spacing } from '../../utils/styleguide';
import AlternativeLoginProviders from './AlternativeLoginProviders';
import useResetPasswordUrl from './useResetPasswordUrl';
import { captureException } from '../../utils/tracking/sentry/sentry';
import firebaseApp from '../../utils/firebase';
import { useAuth } from '../../utils/authContext';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]}px;
  margin-bottom: ${spacing[3]}px;
`;

const TextBox = styled(TextInput)`
  margin-bottom: ${spacing[3]}px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Submit = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
`;

const PROVIDER_MAP = {
  'google.com': 'Google',
  'facebook.com': 'Facebook',
} as const;
const isProvider = (provider: string): provider is keyof typeof PROVIDER_MAP => {
  return !!PROVIDER_MAP[provider as keyof typeof PROVIDER_MAP];
};

type Props = {
  email: string;
  setEmail: (email: string) => void;
  setStep: (step: 'create-password') => void;
};

const StepSignUpEmail = ({ email, setEmail, setStep }: Props) => {
  const { isLoadingUser } = useAuth();

  const [isCheckingSignUpEmail, setIsCheckingSigningUpEmail] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [checkEmailError, setCheckEmailError] = useState<unknown>();
  const [providers, setProviders] = useState<string[] | undefined>();

  useOnMountEffect(() => {
    sendEvent({
      name: EVENT_NAMES.signUpViewPage,
      properties: undefined,
    });
  });

  const checkSignUpEmail = async (email: string) => {
    setEmail(email);
    setIsCheckingSigningUpEmail(true);
    setCheckEmailError(undefined);
    setProviders(undefined);

    try {
      let error: { code: 'AlreadyHasPassword' | 'AlreadyHasOtherProviders' } | undefined;
      let step: 'create-password' | undefined;
      /**
       * TODO: We should update this flow according to updated best practices and feature support.
       * This method is deprecated and does no longer return available providers for security reasons.
       * Read more: https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection
       */
      const availableProviders = await fetchSignInMethodsForEmail(getAuth(firebaseApp), email);

      if (availableProviders.includes('password')) {
        error = { code: 'AlreadyHasPassword' };
      } else if (availableProviders.length) {
        error = { code: 'AlreadyHasOtherProviders' };
      } else {
        step = 'create-password';
      }

      setIsCheckingSigningUpEmail(false);
      setProviders(availableProviders);
      if (error) {
        setCheckEmailError(error);
      }
      if (step) {
        setStep(step);
      }
    } catch (error) {
      sendEvent({
        name: EVENT_NAMES.checkSignUpEmailFailed,
        properties: {
          error,
        },
      });
      captureException(error);

      setIsCheckingSigningUpEmail(false);
      setCheckEmailError(error);
    }
  };

  const continueSignUp: DOMAttributes<HTMLFormElement>['onSubmit'] = async (e) => {
    e.preventDefault();
    const hasEmailValidationError = runValidations(validations.email, email);
    if (hasEmailValidationError) {
      return setShowAllErrors(true);
    }

    await checkSignUpEmail(email);
  };

  return (
    <>
      <Header>
        <Typography variant="h4">Create account</Typography>
        <Typography variant="h5">See your donations and stay updated!</Typography>
      </Header>
      <form onSubmit={continueSignUp}>
        <TextBox
          autoFocus
          label="Your email"
          id="email"
          type="email"
          value={email}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          validations={validations.email}
          validateWithoutFocus={showAllErrors}
          error={getEmailCheckError({ error: checkEmailError, providers, email })}
        />
        <Submit
          type="submit"
          label={'Continue'}
          disabled={!email}
          loading={isCheckingSignUpEmail || isLoadingUser}
          variant="solid"
          css={css`
            max-width: 328px;
            width: 100%;
          `}
        />
      </form>
      <AlternativeLoginProviders type="signup" />
    </>
  );
};

const LoginButton = ({ email }: { email: string }) => {
  const router = useRouter();
  return (
    <GhostButton
      href={{
        pathname: router.pathname,
        query: {
          ...router.query,
          step: 'login',
          email,
        },
      }}
      label="Log in"
      hideUnderline
      color={colors.info}
      textVariant={'detail'}
    />
  );
};

const ResetPasswordButton = ({ email }: { email: string }) => {
  const resetPasswordUrl = useResetPasswordUrl(email);
  return (
    <GhostButton
      href={resetPasswordUrl}
      label="Reset your password"
      hideUnderline
      color={colors.info}
      textVariant={'detail'}
    />
  );
};

const getEmailCheckError = ({
  error,
  email,
  providers,
}: {
  error: unknown;
  email: string;
  providers?: string[];
}) => {
  const mappedProviders = providers?.filter(isProvider).map((provider) => PROVIDER_MAP[provider]);

  if ((error as { code: string } | undefined)?.code === 'AlreadyHasOtherProviders') {
    return (
      <>
        Your previous login methods:{' '}
        {mappedProviders?.map((provider) => (
          <ProviderButton
            key={provider}
            providerName={provider}
            label={provider}
            emailSuggestion={email}
            variant="detail"
          />
        ))}
        . Otherwise <ResetPasswordButton email={email} />
      </>
    );
  }

  if ((error as { code: string } | undefined)?.code === 'AlreadyHasPassword') {
    return (
      <>
        You already have an account. <LoginButton email={email} /> instead!
      </>
    );
  }
};

export default StepSignUpEmail;
