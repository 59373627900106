import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import IconSvg from '../../assets/svg/x-ol.svg';
import { colors, spacing } from '../../utils/styleguide';
import Typography from './text/Typography';

const Container = styled.div`
  display: flex;
  position: relative;
  ${({ color }) => `background-color: ${color};`}
  padding: ${spacing[2]}px;
  margin: ${spacing[3]}px 0;
  white-space: pre-wrap;
`;

const CloseButton = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 0 ${spacing[1]}px;
  stroke: ${colors.white};
  padding: 0;
  -webkit-appearance: none;
  border: none;
  background-color: inherit;
  right: 0;
  cursor: pointer;
`;

const Icon = styled(IconSvg)`
  width: 100%;
  height: 100%;
`;

const TYPE_TO_COLOR = {
  error: colors.red,
  info: colors.blue,
};

type Props = {
  message?: string;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  type: keyof typeof TYPE_TO_COLOR;
  children?: ReactNode;
};

const FeedbackBox = ({ message, onClose, className, type = 'info', children }: Props) => {
  return (
    <Container className={className} color={TYPE_TO_COLOR[type]}>
      <div style={{ flex: 1 }}>
        <Typography useBuiltInMargins color={colors.white}>
          {message || children}
        </Typography>
      </div>
      {onClose && (
        <CloseButton onClick={onClose}>
          <Icon />
        </CloseButton>
      )}
    </Container>
  );
};

export default FeedbackBox;
