import React from 'react';
import FeedbackBox from './FeedbackBox';

type Props = {
  message?: string;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
};

const ErrorBox = (props: Props) => {
  return <FeedbackBox type={'error'} {...props} />;
};

export default ErrorBox;
