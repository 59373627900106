import { handleErrorCategory } from '../api';
import config from '../config';

export const getSignUpWithEmailLink = async ({ email }: { email: string }) => {
  const response = await fetch(`${config.API_URL}authentication/v2/firebase/email-link`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      url: `${config.HOST_URL}/my-dashboard`,
    }),
  });
  if (!response.ok) {
    const error = await handleErrorCategory('Api', response);
    throw error;
  }
};
